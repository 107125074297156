import React from 'react'
import TryNow from '../components/molecules/TryNow/TryNow'
import Header from "../components/atoms/header";

export default function tryNow() {
  return (
    <div>
        <Header />
     <TryNow />
    </div>
  )
}
