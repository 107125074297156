import React from 'react';
import * as styles from "./TryNow.module.scss"; 

export default function TryNow() {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Ease the way you make great impressions</h1>
      <img className={styles.image} src='https://res.cloudinary.com/dsg2ktuqk/image/upload/v1726601162/Templates/Rectangle_42179_iak0k3.png' alt='banner'/>
      <p className={styles.desc}>Boost your presentations, meetings and all material you care about</p>
      <div className={styles.buttons}>
        <button onClick={() => window.open("https://accounts.masterwizr.com/sign-up", "_blank")} className={styles.tryBtn}>Try for free now</button>
        <button onClick={() => window.open("https://accounts.masterwizr.com/sign-up", "_blank")} className={styles.buyBtn}>Buy now</button>
      </div>
    </div>
  )
}
